<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import EmailTemplates from "@/components/crm/emailTemplates.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Email Templates",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, EmailTemplates },
    data() {
        return {
            title: "Email Templates",
            items: [
                {
                    text: "Utility",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <EmailTemplates />
    </Layout>
</template>
