<template>
    <b-modal :no-enforce-focus="true" title="Create an Email Template" id="createEmailTemplateModal" hide-footer size="lg">
        <b-overlay :show="busySaving">
            <b-row class="d-flex justify-content-center">
                <b-row class="d-flex justify-content-center">
                    <b-form class="col-lg-10">
                        <b-form-group label="Type:">
                            <b-form-input v-model="ckData.type" size="sm"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Name of this template:">
                            <b-form-input v-model="ckData.name" size="sm"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Description:">
                            <b-form-input v-model="ckData.description" size="sm"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Subject:">
                            <b-form-input v-model="ckData.subject" size="sm"></b-form-input>
                        </b-form-group>
                    </b-form>
                    <b-row class="col-lg-10">
                        <ckeditor v-model="ckData.body" :config="editorConfig" @ready="onEditorReady"></ckeditor>
                    </b-row>
                </b-row>
            </b-row>
            <div class="modal-footer mt-2">
                <b-button @click="createTemplate" type="submit" variant="primary">Create Template</b-button>
                <b-button variant="success" @click="closeModal()">Cancel</b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { adminMethods, crmComputed, crmMethods } from "@/state/helpers";
import CKEditor from "ckeditor4-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { ckeditor: CKEditor.component },
    mixins: [validationMixin],
    data: () => ({
        busySaving: false,
        editor: null,
        // editor: ClassicEditor,
        ckData: {
            type: "",
            name: "",
            subject: "",
            description: "",
            body: "",
        },
        editorConfig: {
            height: "55vh",
            on: {
                instanceReady: function (event) {
                    let dataProcessor = event.editor.dataProcessor;
                    let htmlFilter = dataProcessor && dataProcessor.htmlFilter;

                    if (htmlFilter) {
                        htmlFilter.addRules({
                            elements: {
                                p: function (element) {
                                    let style = element.attributes.style;
                                    // Ensure style attribute exists before attempting to modify it
                                    if (style) {
                                        style = style.replace(/font-family:.*?;/g, "font-family: 'Open Sans','sans-serif';");
                                        element.attributes.style = style;
                                    }
                                    return element;
                                },
                            },
                        });
                    }
                },
            },
            extraPlugins:
                "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,button,tableresize,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,indentblock,indentlist,justify,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastefromword,pastetext,preview,removeformat,resize,save,menubutton,scayt,selectall,showblocks,showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,tabletools,templates,toolbar,undo,wysiwygarea",
        },
    }),
    computed: {
        ...crmComputed,
    },
    methods: {
        ...adminMethods,
        ...crmMethods,
        createTemplate() {
            Swal.fire({
                title: `Create this email template?`,
                // text: `${item.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    this.saveEmailTemplate(this.ckData).then(() => {
                        Swal.fire(`Email Template Created`, "", "success");
                        //refreshes the table on the screen
                        this.setEmailTemplates();
                        this.closeModal();
                    });
                }
            });
        },
        onEditorReady(editor) {
            this.editor = editor;
            this.$nextTick(() => {
                const modal = document.querySelector('.modal-content');
                //eslint-disable-next-line
                console.log("modal", modal);
                if (modal) {
                    modal.removeAttribute('tabindex');
                }
            });
        },
        closeModal() {
            this.$bvModal.hide("createEmailTemplateModal");
        },
    },
    mounted() {

    },
};
</script>
<style>
input[readonly] {
    background-color: red;
}
.ck {z-index: 9999 !important;}
</style>
