<template>
    <b-modal :no-enforce-focus="true" title="Edit Email Template" id="editEmailTemplateModal" class="no-tabindex" hide-footer size="lg">
        <b-overlay :show="busySaving">
            <b-row class="d-flex justify-content-center">
                <div v-if="selected_template_details.merge_keys.length > 0" class="col-lg-10 mb-4">
                    <h4 class="mb-2">Merge Keys Section:</h4>
                    <p>You can use the following email merge keys in your template.</p>
                    <p>They are automatically replaced with values when the template is used.</p>
                    <webix-ui :config="ui" v-model="selected_template_details.merge_keys"></webix-ui>
                </div>

                <b-form class="col-lg-10">
                    <hr />
                    <h4 class="mb-4">Email Section:</h4>
                    <b-form-group label="Type:">
                        <b-form-input v-model="ckData.type" size="sm"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Name of this template:">
                        <b-form-input v-model="ckData.name" size="sm"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Description:">
                        <b-form-input v-model="ckData.description" size="sm"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Subject:">
                        <b-form-input v-model="ckData.subject" size="sm"></b-form-input>
                    </b-form-group>
                </b-form>

                <b-row class="col-lg-10">
                    <b-alert class="w-100" variant="danger" :show="error" dismissible>
                        You must use all merge keys: <b>{{ error_msg }}</b> not used.</b-alert
                    >
                    <ckeditor v-model="ckData.body" :config="editorConfig" @ready="onEditorReady"></ckeditor>
                </b-row>
            </b-row>
            <div class="modal-footer mt-2">
                <b-button @click="editTemplate" type="submit" variant="primary">Save</b-button>
                <b-button variant="success" @click="closeModal()">Cancel</b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { adminMethods, crmComputed, crmMethods } from "@/state/helpers";
import CKEditor from "ckeditor4-vue";
// import ClassicEditor from "@ckeditor/ckeditor";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { ckeditor: CKEditor.component },
    mixins: [validationMixin],
    data: () => ({
        error: false,
        error_msg: "",
        busySaving: false,
        editor: null,
        ckData: {
            id: "",
            type: "",
            name: "",
            subject: "",
            description: "",
            body: "",
        },
        editorConfig: {
            height: "55vh",
            on: {
                instanceReady: function (event) {
                    let dataProcessor = event.editor.dataProcessor;
                    let htmlFilter = dataProcessor && dataProcessor.htmlFilter;

                    if (htmlFilter) {
                        htmlFilter.addRules({
                            elements: {
                                p: function (element) {
                                    let style = element.attributes.style;
                                    // Ensure style attribute exists before attempting to modify it
                                    if (style) {
                                        style = style.replace(/font-family:.*?;/g, "font-family: 'Open Sans','sans-serif';");
                                        element.attributes.style = style;
                                    }
                                    return element;
                                },
                            },
                        });
                    }
                },
            },
            extraPlugins:
                "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,button,tableresize,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,indentblock,indentlist,justify,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastefromword,pastetext,preview,removeformat,resize,save,menubutton,scayt,selectall,showblocks,showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,tabletools,templates,toolbar,undo,wysiwygarea",
        },
    }),
    computed: {
        ...crmComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "mergeKeysTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                //hover: "datatableHover",
                minHeight: 60,
                // rowHeight: 40,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                // pager: {
                //     template: "{common.prev()} {common.pages()} {common.next()}",
                //     container: "mergeKeysTablePaging",
                //     size: 20,
                //     group: 5,
                // },
                columns: [
                    {
                        id: "display_name",
                        header: ["Name"],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                    },
                    {
                        id: "merge_key",
                        header: ["Merge Key"],
                        tooltip: false,
                        fillspace: 1,
                        template: '<div style="cursor: pointer;" class="insertMergeKey">#merge_key#</div>',
                    },
                ],
                ready: function () {
                    const table = window.webix.$$("mergeKeysTable");
                    table.refresh();
                },
                on: {},
                onClick: {
                    //eslint-disable-next-line
                    insertMergeKey: function(id, e, trg) {
                        let item = this.getItem(e.row);
                        el.editor.insertText(item.merge_key);                        
                    }
                },
            };
            return ui;
        },
    },
    methods: {
        ...adminMethods,
        ...crmMethods,
        editTemplate() {
            this.error = false;
            this.error_msg = "";

            if (this.selected_template_details.merge_keys.length > 0) {
                for (const key of this.selected_template_details.merge_keys) {
                    let contains = this.ckData.body.includes(key.merge_key);

                    if (contains == false) {
                        this.error_msg += key.merge_key;
                        return (this.error = true);
                    }
                }
            }

            Swal.fire({
                title: `Save this email template?`,
                // text: `${item.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    this.saveEmailTemplate(this.ckData).then(() => {
                        Swal.fire(`Email Template Saved`, "", "success");
                        //refreshes the table on the screen
                        this.setEmailTemplates();
                        this.closeModal();
                    });
                }
            });
        },
        onEditorReady(editor) {
            this.editor = editor;
            this.$nextTick(() => {
                const modal = document.querySelector('.modal-content');
                //eslint-disable-next-line
                console.log("modal", modal);
                if (modal) {
                    modal.removeAttribute('tabindex');
                }
            });
        },
        closeModal() {
            this.$bvModal.hide("editEmailTemplateModal");
        },
    },
    mounted() {
        this.ckData.id = this.selected_template_details.id;
        this.ckData.type = this.selected_template_details.type;
        this.ckData.name = this.selected_template_details.name;
        this.ckData.subject = this.selected_template_details.subject;
        this.ckData.description = this.selected_template_details.description;
        this.ckData.body = this.selected_template_details.body;
    },
};
</script>
<style>
input[readonly] {
    background-color: red;
}
</style>
