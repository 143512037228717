<template>
    <div>
        <div v-if="email_templates_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading Email Templates...
        </div>
        <div v-else>
            <webix-ui :config="ui" v-model="email_templates"></webix-ui>
            <div id="emailTemplatesTablePaging"></div>
        </div>
        <EditEmailTemplateModal v-if="showModal_editModal" />
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
import EditEmailTemplateModal from "../modals/editEmailTemplateModal.vue";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    components: { EditEmailTemplateModal },
    data: () => ({ showModal_editModal: false }),
    computed: {
        ...crmComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "emailTemplatesTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                minHeight: 60,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "emailTemplatesTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {
                        id: "actions",
                        header: { text: "Actions", css: { "text-align": "center" } },
                        template: function () {
                            return `<div class="tableIcons">
                                <i v-b-tooltip.hover title="Edit Group" class="mdi mdi-pencil editAction"></i>
                                <i class="mdi mdi-trash-can deleteAction"></i>
                            </div>`;
                        },
                    },
                    {
                        id: "name",
                        header: ["Name", { content: "selectFilter" }],
                        tooltip: false,
                        fillspace: 1,
                        sort: "text",
                    },
                    {
                        id: "type",
                        header: ["Type", { content: "selectFilter" }],

                        tooltip: false,
                        fillspace: 1,
                    },
                    {
                        id: "description",
                        header: ["Description", { content: "selectFilter" }],
                        tooltip: false,
                        fillspace: 1,
                    },
                ],
                ready: function () {},
                on: {},
                onClick: {
                    //eslint-disable-next-line
                    editAction: function (id, e, trg) {
                        //eslint-disable-next-line
                        let item = this.getItem(e.row);
                        //eslint-disable-next-line
                        let el = this.$scope.$parent;

                        el.setSelectedTemplateDetails(item);

                        el.showEmailTemplate();
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        const el = this.$scope.$parent;
                        const item = this.getItem(e.row);

                        el.handleSwal(item, e);
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...crmMethods,
        showEmailTemplate() {
            this.showModal_editModal = true;
            this.$nextTick(() => {
                this.$bvModal.show("editEmailTemplateModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "editEmailTemplateModal") {
                        this.showModal_editModal = false;
                    }
                });
            });
        },
        handleSwal(item) {
            Swal.fire({
                title: `Delete email template: "${item.name}"`,
                text: `This cannot be undone`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.deleteEmailTemplate({ id: item.id }).then(() => {
                        Swal.fire("Email template deleted", "", "success");
                        this.setEmailTemplates();
                    });
                }
            });
        },
    },
    mounted() {
        this.setEmailTemplates();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
