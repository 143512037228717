<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-2 d-flex justify-content-end">
                            <button type="button" class="btn btn-success mb-2 mr-2" @click="showCreateTemplate">
                                <i class="mdi mdi-plus mr-1"></i> Create Template
                            </button>
                        </div>
                        <EmailTemplatesTable />
                    </div>
                </div>
            </div>
        </div>
        <CreateEmailTemplateModal v-if="showModal_createTemplate" />
    </div>
</template>

<script>
import EmailTemplatesTable from "./tables/emailTemplatesTable.vue";
import CreateEmailTemplateModal from "./modals/createEmailTemplateModal.vue";

export default {
    components: { EmailTemplatesTable, CreateEmailTemplateModal },
    data: () => ({
        showModal_createTemplate: false,
    }),
    computed: {},
    methods: {
        showCreateTemplate() {
            this.showModal_createTemplate = true;
            this.$nextTick(() => {
                this.$bvModal.show("createEmailTemplateModal");
                this.$root.$once("bv::modal::hide", (event) => {
                    if (event.type == "hide" && event.componentId == "createEmailTemplateModal") {
                        this.showModal_createTemplate = false;
                    }
                });
            });
        },
    },
    mounted() {},
};
</script>

<style></style>
